<template>
  <div>
    <!-- Header should be outside the v-if/v-else blocks -->
    <template v-if="preview">
      <slot name="header" :planners="planners">
        <v-card-subtitle class="d-flex pt-2">
          <p class="heading text-h6 mb-0">{{ $t("t_planners") }}</p>
          <v-spacer></v-spacer>
          <v-btn small class="mt-1" v-if="planners && planners.length" text :to="{ name: 'planners' }">
            {{ $t("t_view_all") }} <v-icon small class="ml-1">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-subtitle>
      </slot>
    </template>

    <!-- Show no results message -->
    <h4 class="pa-4 pt-0" v-if="(planners?.length == 0 && !loading) || !$online">
      {{ $t("t_no_planners") }} {{ !$online ? " : " + $t("t_no_network_connection") : "" }}
    </h4>
    <div v-else>
      <template v-if="planners && Array.isArray(planners) && planners.length">
        <v-list class="transparent pt-0" flat style="overflow: visible">
          <v-list-item-group>
            <div v-for="(item, index) in planners" :key="`${item.plannerId}-${item.id}-${index}`">
              <v-list-item
                link
                @click="navigateToPlanner(item)"
                class="rounded mx-2 mb-1 grey lighten-3"
                :class="{ 'darken-4': $vuetify.theme.dark }"
              >
                <v-list-item-content>
                  <div class="caption grey--text my-0 py-0 mb-1 d-flex align-center" style="font-size: 9px">
                    <span class="text-uppercase" style="padding-top: 1px">{{
                      item.plannerType ? $t("t_planner_type_" + item.plannerType) : ""
                    }}</span>
                    <template v-if="item.selectedDueDate">
                      <v-chip outlined :color="statusColor(item.selectedDueDate.status)" x-small label class="ml-2">
                        {{ getStatusText(item.selectedDueDate.status) }}
                        <template
                          v-if="
                            item.currentSubmission?.dueDateId == item.selectedDueDate?.id &&
                            item.selectedDueDate?.completionPercentage
                          "
                        >
                          - {{ item.currentSubmission?.completionPercentage || 0 }}%
                        </template>
                      </v-chip>
                    </template>
                    <template v-else-if="item.currentSubmission">
                      <v-chip
                        outlined
                        :color="statusColor(item.currentSubmission?.status ? item.currentSubmission?.status : 0)"
                        x-small
                        label
                        class="ml-2"
                      >
                        {{ getStatusText(item.currentSubmission?.status ? item.currentSubmission?.status : 0) }}
                        <template v-if="item.currentSubmission?.completionPercentage">
                          - {{ item.currentSubmission?.completionPercentage }}%
                        </template>
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip outlined :color="statusColor(item.status ? item.status : 0)" x-small label class="ml-2">
                        {{ getStatusText(item.status ? item.status : 0) }}
                      </v-chip>
                    </template>
                  </div>
                  <v-list-item-title class="font-weight-medium">
                    {{ item.plannerName }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="mt-1">
                    <v-row no-gutters align="center">
                      <v-col cols="auto" class="mr-4">
                        <template v-if="item.selectedDueDate">
                          <v-menu offset-y v-model="item.selectDueDateOpen">
                            <template v-slot:activator="{ on, attrs }">
                              <span class="due-label text-caption mr-2 text-small pt-1">{{ $t("t_due") }}:</span>
                              <v-btn @click.stop small class="px-2 due-date-btn" v-bind="attrs" v-on="on">
                                {{ formatDateTime(item.selectedDueDate.dueDateTime) }}
                                <v-icon small class="ml-1">mdi-chevron-down</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <template v-for="(dueDate, dIndex) in getDueDatesLimited(item, dueDateLimit)">
                                <v-list-item
                                  :key="`${item.id}-${dueDate.id}-${dIndex}`"
                                  @click.stop="selectDueDate(item, dueDate)"
                                >
                                  <v-list-item-title>
                                    {{ formatDateTime(dueDate.dueDateTime) }}
                                  </v-list-item-title>
                                </v-list-item>
                              </template>

                              <!-- View All button if there are more than dueDateLimit dates -->
                              <template v-if="getTotalDueDates(item) > dueDateLimit">
                                <v-divider></v-divider>
                                <v-list-item @click.stop="showAllDueDates(item)">
                                  <v-list-item-title class="text-center primary--text">
                                    {{ $t("t_view_all") }} ({{ getTotalDueDates(item) }})
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-list>
                          </v-menu>

                          <planner-due-dates-dialog
                            v-if="currentDialogPlanner && currentDialogPlanner.id === item.id"
                            :show="showDialog"
                            :planner="item"
                            @close="closeDialog"
                            @select-date="navigateToPlannerWithDate"
                          />
                        </template>
                        <div v-else-if="item.currentSubmission" class="no-due-date text-caption">
                          {{ $t("t_open_ended") }}

                          <v-btn
                            x-small
                            class="ml-2"
                            v-if="item.submittedSubmissionsCount > 0"
                            @click.stop="showHistory(item)"
                            >History ({{ item.submittedSubmissionsCount }})</v-btn
                          >
                        </div>
                        <div v-else class="no-due-date text-caption">
                          {{ $t("t_open_ended") }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </template>

      <v-row v-if="loading">
        <v-col class="d-flex flex-column align-center justify-center mx-3 mt-2 mb-0">
          <v-progress-linear indeterminate color="primary" size="32"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="!endOfResults && !loading && planners && planners.length > 0"
            class="mx-3"
            @click="loadMorePlanners"
          >
            {{ $t("t_load_more") }}
          </v-btn>
        </v-col>
        <v-col class="text-right" v-if="!preview">
          <div class="text-caption grey--text mx-3 mb-2 mt-2">
            {{ $t("t_showing") }} {{ planners.length }} {{ $t("t_of") }} {{ totalItems }}
          </div>
        </v-col>
      </v-row>

      <planner-submissions-dialog
        v-if="currentDialogPlanner"
        :show="showSubmissionsDialog"
        :planner="currentDialogPlanner"
        @close="closeSubmissionsDialog"
        @select-submission="navigateToPlannerSubmission"
      />
    </div>
  </div>
</template>

<script>
import { PlannerService } from "@services";
import helpers from "@/mixins/helpers";
import { DateTime } from "luxon";
import PlannerDueDatesDialog from "./PlannerDueDatesDialog.vue";
import PlannerSubmissionsDialog from "./PlannerSubmissionsDialog.vue";

export default {
  name: "PlannerList",
  components: {
    PlannerDueDatesDialog,
    PlannerSubmissionsDialog,
  },

  mixins: [helpers],

  props: {
    takeNum: {
      type: Number,
      default: 5,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
  },

  computed: {
    showPagination() {
      return this.totalItems > this.options.itemsPerPage;
    },
  },

  data: () => ({
    loading: false,
    planners: [],
    dueDateLimit: 4,
    skipNum: 0,
    totalItems: 0,
    endOfResults: false,
    currentDialogPlanner: null,
    showDialog: false,
    showSubmissionsDialog: false,
  }),

  methods: {
    navigateToPlanner(item) {
      let path = `/planner/${item.plannerId}/${item.id}`;
      if (item.currentSubmission && !item.selectedDueDate) {
        path += `/0/${item.currentSubmission.id}`;
      } else if (item.selectedDueDate?.id) {
        path += `/${item.selectedDueDate.id}`;
        if (item.selectedDueDate.submissionId) {
          path += `/${item.selectedDueDate.submissionId}`;
        }
      }
      this.$router.push(path);
    },

    getDueDates(item) {
      const now = DateTime.local();
      const futureDueDates = [];
      const pastDueDates = [];
      const completedDueDates = [];

      item.dueDates.forEach((date) => {
        const dateTime = DateTime.fromISO(date.dueDateTime, { zone: "utc" }).toLocal();
        const processedDate = {
          ...date,
          dateTime,
        };

        // Separate completed due dates
        if (date.status === 2) {
          // Completed status
          completedDueDates.push(processedDate);
        }
        // Future due dates (not completed)
        else if (dateTime > now) {
          futureDueDates.push(processedDate);
        }
        // Past due dates (not completed)
        else if ([0, 1, 3].includes(date.status)) {
          // Pending, InProgress, or Overdue
          pastDueDates.push(processedDate);
        }
      });

      return {
        futureDueDates: futureDueDates.sort((a, b) => a.dateTime.valueOf() - b.dateTime.valueOf()),
        pastDueDates: [
          ...pastDueDates.sort((a, b) => b.dateTime.valueOf() - a.dateTime.valueOf()),
          ...completedDueDates.sort((a, b) => b.dateTime.valueOf() - a.dateTime.valueOf()),
        ],
      };
    },

    selectDueDate(item, dueDate) {
      item.selectedDueDate = dueDate;
      item.selectDueDateOpen = false;
    },

    getSelectedDueDate(planner, futureDueDates, pastDueDates) {
      let selectedDueDate = null;

      // First try to get the due date from current submission
      if (planner.currentSubmission && planner.currentSubmission.dueDateId) {
        selectedDueDate = planner.dueDates.find((dueDate) => dueDate.id === planner.currentSubmission.dueDateId);
        if (selectedDueDate) {
          selectedDueDate.completionPercentage = planner.currentSubmission.completionPercentage;
        }
      }

      // If no current submission or no matching due date, fallback to the next due date
      if (!selectedDueDate) {
        selectedDueDate =
          futureDueDates.length > 0 ? futureDueDates[0] : pastDueDates.length > 0 ? pastDueDates[0] : null;
      }

      return selectedDueDate;
    },

    async loadPlanners(refresh = false) {
      this.loading = true;
      try {
        const params = {
          "paging.orderByField": "id",
          "paging.ascending": false,
          "paging.skipNum": refresh ? 0 : this.skipNum,
          "paging.takeNum": this.takeNum,
          contentwildcardsearch: this.search,
        };

        if (refresh) {
          this.planners = [];
          this.skipNum = 0;
          this.endOfResults = false;
        }

        const r = await PlannerService.getPlannerAssignments(params);
        this.totalItems = r.meta.numTotal;

        const plannerAssignments = r.data?.data ? r.data.data : r.data;
        const processedPlanners = plannerAssignments.map((planner) => {
          const { futureDueDates, pastDueDates } = this.getDueDates(planner);
          return {
            ...planner,
            futureDueDates,
            pastDueDates,
            selectedDueDate: this.getSelectedDueDate(planner, futureDueDates, pastDueDates),
            selectDueDateOpen: false,
            showAllDatesDialog: false,
          };
        });

        if (!refresh) {
          this.planners = [...(this.planners || []), ...processedPlanners];
        } else {
          this.planners = processedPlanners;
        }

        this.endOfResults = r.meta.endOfResults;
        this.skipNum += this.takeNum;
      } catch (error) {
        console.error("Error loading planners:", error);
      } finally {
        this.loading = false;
      }
    },

    loadMorePlanners() {
      this.loadPlanners();
    },

    getDueDatesLimited(item) {
      const allDates = [
        ...item.futureDueDates,
        ...item.pastDueDates.filter((d) => d.status !== 2),
        ...item.pastDueDates.filter((d) => d.status === 2),
      ];
      return allDates.slice(0, this.dueDateLimit);
    },

    getTotalDueDates(item) {
      return item.futureDueDates.length + item.pastDueDates.length;
    },

    showAllDueDates(item) {
      console.log("Opening dialog for item:", item.id);
      item.selectDueDateOpen = false;
      this.currentDialogPlanner = item;
      this.showDialog = true;
    },

    closeDialog() {
      this.showDialog = false;
      this.currentDialogPlanner = null;
    },

    navigateToPlannerWithDate(item, dueDate) {
      this.closeDialog();
      let path = `/planner/${item.plannerId}/${item.id}/${dueDate.id}`;
      if (dueDate.submissionId) {
        path += `/${dueDate.submissionId}`;
      }
      this.$router.push(path);
    },

    getPlannerTypeLabel(type) {
      return type === "safety" ? "Safety Planner" : type === "report" ? "Report" : "Task Planner";
    },

    showHistory(item) {
      this.currentDialogPlanner = item;
      this.showSubmissionsDialog = true;
    },

    closeSubmissionsDialog() {
      this.showSubmissionsDialog = false;
      this.currentDialogPlanner = null;
    },

    navigateToPlannerSubmission(item, submission) {
      this.closeSubmissionsDialog();
      const path = `/planner/${item.plannerId}/${item.id}/0/${submission.id}`;
      this.$router.push(path);
    },
  },
  mounted() {
    this.loadPlanners(true);
  },

  watch: {
    search() {
      this.loadPlanners(true);
    },
  },
};
</script>

<style lang="scss">
.planners-list {
  .due-label {
    opacity: 0.7;
  }
  .no-due-date {
    font-weight: normal;
  }
  .due-date-btn {
    text-transform: none;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif !important;
  }
}

.v-list {
  overflow: visible !important;
}

.v-list-item-group {
  overflow: visible !important;
}
</style>

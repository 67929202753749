var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.show,"max-width":"400"},on:{"input":function($event){return _vm.$emit('close')}}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("t_all_due_dates")))]),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[(_vm.planner.futureDueDates.length)?[_c('v-subheader',[_vm._v(_vm._s(_vm.$t("t_future_due_dates")))]),_vm._l((_vm.planner.futureDueDates),function(dueDate){return _c('v-list-item',{key:dueDate.id,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select-date', _vm.planner, dueDate)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.formatDateTime(dueDate.dueDateTime))+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":"","color":_vm.statusColor(dueDate.status),"x-small":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(dueDate.status))+" "),(
                    _vm.planner.currentSubmission?.dueDateId == dueDate.id &&
                    _vm.planner.selectedDueDate?.completionPercentage
                  )?[_vm._v(" - "+_vm._s(_vm.planner.currentSubmission?.completionPercentage || 0)+"% ")]:_vm._e()],2)],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)})]:_vm._e(),(_vm.planner.pastDueDates.some((d) => d.status !== 2))?[_c('v-divider'),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("t_past_due_dates")))]),_vm._l((_vm.planner.pastDueDates.filter((d) => d.status !== 2)),function(dueDate){return _c('v-list-item',{key:dueDate.id,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select-date', _vm.planner, dueDate)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.formatDateTime(dueDate.dueDateTime))+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":"","color":_vm.statusColor(dueDate.status),"x-small":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(dueDate.status))+" "),(
                    _vm.planner.currentSubmission?.dueDateId == dueDate.id &&
                    _vm.planner.selectedDueDate?.completionPercentage
                  )?[_vm._v(" - "+_vm._s(_vm.planner.currentSubmission?.completionPercentage || 0)+"% ")]:_vm._e()],2)],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)})]:_vm._e(),(_vm.planner.pastDueDates.some((d) => d.status === 2))?[_c('v-divider'),_c('v-subheader',[_vm._v(_vm._s(_vm.$t("t_completed")))]),_vm._l((_vm.planner.pastDueDates.filter((d) => d.status === 2)),function(dueDate){return _c('v-list-item',{key:dueDate.id,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select-date', _vm.planner, dueDate)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.formatDateTime(dueDate.dueDateTime))+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":"","color":_vm.statusColor(dueDate.status),"x-small":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(dueDate.status))+" "),(
                    _vm.planner.currentSubmission?.dueDateId == dueDate.id &&
                    _vm.planner.selectedDueDate?.completionPercentage
                  )?[_vm._v(" - "+_vm._s(_vm.planner.currentSubmission?.completionPercentage || 0)+"% ")]:_vm._e()],2)],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)})]:_vm._e()],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("t_close")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog :value="show" @input="$emit('close')" max-width="400">
    <v-card>
      <v-card-title>{{ $t("t_all_due_dates") }}</v-card-title>
      <v-card-text>
        <v-list dense>
          <!-- Future due dates -->
          <template v-if="planner.futureDueDates.length">
            <v-subheader>{{ $t("t_future_due_dates") }}</v-subheader>
            <v-list-item
              v-for="dueDate in planner.futureDueDates"
              :key="dueDate.id"
              link
              @click.stop="$emit('select-date', planner, dueDate)"
            >
              <v-list-item-title>
                {{ formatDateTime(dueDate.dueDateTime) }}
                <v-chip outlined :color="statusColor(dueDate.status)" x-small class="ml-2">
                  {{ getStatusText(dueDate.status) }}
                  <template
                    v-if="
                      planner.currentSubmission?.dueDateId == dueDate.id &&
                      planner.selectedDueDate?.completionPercentage
                    "
                  >
                    - {{ planner.currentSubmission?.completionPercentage || 0 }}%
                  </template>
                </v-chip>
              </v-list-item-title>
              <v-list-item-action>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <!-- Past incomplete dates -->
          <template v-if="planner.pastDueDates.some((d) => d.status !== 2)">
            <v-divider></v-divider>
            <v-subheader>{{ $t("t_past_due_dates") }}</v-subheader>
            <v-list-item
              v-for="dueDate in planner.pastDueDates.filter((d) => d.status !== 2)"
              :key="dueDate.id"
              link
              @click.stop="$emit('select-date', planner, dueDate)"
            >
              <v-list-item-title>
                {{ formatDateTime(dueDate.dueDateTime) }}
                <v-chip outlined :color="statusColor(dueDate.status)" x-small class="ml-2">
                  {{ getStatusText(dueDate.status) }}
                  <template
                    v-if="
                      planner.currentSubmission?.dueDateId == dueDate.id &&
                      planner.selectedDueDate?.completionPercentage
                    "
                  >
                    - {{ planner.currentSubmission?.completionPercentage || 0 }}%
                  </template>
                </v-chip>
              </v-list-item-title>
              <v-list-item-action>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <!-- Completed dates -->
          <template v-if="planner.pastDueDates.some((d) => d.status === 2)">
            <v-divider></v-divider>
            <v-subheader>{{ $t("t_completed") }}</v-subheader>
            <v-list-item
              v-for="dueDate in planner.pastDueDates.filter((d) => d.status === 2)"
              :key="dueDate.id"
              link
              @click.stop="$emit('select-date', planner, dueDate)"
            >
              <v-list-item-title>
                {{ formatDateTime(dueDate.dueDateTime) }}
                <v-chip outlined :color="statusColor(dueDate.status)" x-small class="ml-2">
                  {{ getStatusText(dueDate.status) }}
                  <template
                    v-if="
                      planner.currentSubmission?.dueDateId == dueDate.id &&
                      planner.selectedDueDate?.completionPercentage
                    "
                  >
                    - {{ planner.currentSubmission?.completionPercentage || 0 }}%
                  </template>
                </v-chip>
              </v-list-item-title>
              <v-list-item-action>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">{{ $t("t_close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from "@/mixins/helpers";

export default {
  name: "PlannerDueDatesDialog",
  mixins: [helpers],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    planner: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "select-date"],
};
</script>

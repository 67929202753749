<template>
  <div class="bg-main planners vh-100">
    <VuePullRefresh
      :key="pullKey"
      :on-refresh="appRefresh"
      :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
    >
      <v-container class="pt-0 px-0 pb-10 bg-main" fluid>
        <v-alert
          v-if="!$online"
          dense
          tile
          color="gray"
          icon="mdi-alert-circle-outline"
          class="alert-offline text-headline"
        >
          {{ $t("t_no_connection") }}
        </v-alert>
        <v-card flat class="bg-main" tile>
          <v-card-text class="d-flex pb-0" v-if="$platform == 'web'">
            <p class="headline mb-0">{{ $t("t_planners") }}</p>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$vuetify.breakpoint.mdAndUp"
              icon
              class="ml-2"
              :class="{ rotate: this.$store.state.loader.loading }"
              @click="refresh"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-text>
          <v-row class="mx-1 mb-4 mt-0">
            <v-col cols="12" sm="8" lg="6" xl="5">
              <v-text-field
                class="my-0 pt-2"
                v-model="search"
                clearable
                hide-details
                single-line
                prepend-inner-icon="mdi-magnify"
                :label="$t('t_search')"
                @input="debouncedSearch"
              ></v-text-field>
            </v-col>
          </v-row>

          <PlannerList ref="plannerList" :takeNum="25" :preview="false" :search="search" />
        </v-card>
      </v-container>
    </VuePullRefresh>
  </div>
</template>

<script>
import PlannerList from "@/components/planners/PlannerList.vue";
import helpers from "@/mixins/helpers";
import { debounce } from "lodash";
import VuePullRefresh from "vue-pull-refresh";

export default {
  name: "Planners",

  metaInfo() {
    return {
      title: this.$t("t_planners"),
    };
  },

  components: {
    PlannerList,
    VuePullRefresh,
  },

  mixins: [helpers],

  data() {
    return {
      search: "",
      pullKey: 0,
    };
  },

  methods: {
    debouncedSearch: debounce(function () {
      this.$refs.plannerList?.loadPlanners(true);
    }, 500),

    refresh() {
      this.$refs.plannerList?.loadPlanners(true);
    },

    appRefresh() {
      this.$refs.plannerList?.loadPlanners(true);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.plannerList?.loadPlanners(true);
    });
  },
};
</script>

<style lang="scss">
.planners {
  .rotate {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .due-date-btn {
    text-transform: none;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif !important;
  }
  .no-due-date {
    font-weight: normal;
  }
}
</style>

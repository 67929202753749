<template>
  <v-dialog :value="show" @input="$emit('close')" max-width="400">
    <v-card>
      <v-card-title>{{ $t("t_history") }}</v-card-title>
      <v-card-text>
        <v-list dense>
          <template v-if="loading">
            <v-list-item>
              <v-list-item-content class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else-if="submissions.length">
            <v-list-item
              v-for="submission in submissions"
              :key="submission.id"
              link
              @click="$emit('select-submission', planner, submission)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ submission.completedOn ? formatDateTime(submission.completedOn) : "Active Planner" }}
                  <v-chip label outlined :color="statusColor(submission.status)" x-small class="ml-2">
                    {{ getStatusText(submission.status) }}
                    <template v-if="submission.completionPercentage">
                      - {{ submission.completionPercentage }}%
                    </template>
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle v-if="submission.submittedBy" class="mt-1">
                  {{ $t("t_submitted_by") }}: {{ submission.submittedBy }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <template v-else>
            <v-list-item>
              <v-list-item-content class="text-center">
                {{ $t("t_no_submissions") }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">{{ $t("t_close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from "@/mixins/helpers";
import { PlannerService } from "@services";

export default {
  name: "PlannerSubmissionsDialog",
  mixins: [helpers],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    planner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      submissions: [],
    };
  },
  methods: {
    async loadSubmissions() {
      this.loading = true;
      try {
        const params = {
          //plannerAssignmentId: this.planner.id,
          plannerId: this.planner.plannerId,

          "paging.orderByField": "id",
          "paging.ascending": false,
          "paging.skipNum": 0,
          "paging.takeNum": 50,
        };
        const response = await PlannerService.searchPlannerSubmissions(params);
        this.submissions = response.data;
      } catch (error) {
        console.error("Error loading submissions:", error);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.loadSubmissions();
      }
    },
  },
  mounted() {
    this.loadSubmissions();
  },
  emits: ["close", "select-submission"],
};
</script>
